import React, { FC, ReactElement } from 'react';

import { graphql } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { SliceRenderer } from '../containers';
import { MetaDataResource, transformToMetaData } from '../models/Meta';
import { PrismicSlice } from '../models/Prismic';

interface SingleKnowledgeArticlePageProps {
    data: {
        prismicKnowledgeArticle: {
            dataRaw: {
                body: PrismicSlice[];
                meta: MetaDataResource[];
            };
        };
    };
}

const SingleKnowledgeArticlePage: FC<SingleKnowledgeArticlePageProps> = ({
    data,
}): ReactElement => {
    const apiData = data.prismicKnowledgeArticle.dataRaw;

    const slices = apiData.body;
    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    const backButtonData = {
        url: '/kennis/',
        label: 'Alle artikelen',
    };

    return (
        <ConnectedPage metaData={metaData} className="knowledgeArticle-detail-page">
            <SliceRenderer
                slices={slices}
                backButtonData={backButtonData}
                className="single-knowledgeArticle-page__slice-renderer"
            />
        </ConnectedPage>
    );
};

export default SingleKnowledgeArticlePage;

export const query = graphql`
    query casesPageComponents($id: String) {
        prismicKnowledgeArticle(id: {eq: $id}) {
            dataRaw
        }
    }
`;
